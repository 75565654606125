<template>
  <q-card style="width: 800px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        <small class="q-ml-sm text-size-sm">
          {{ PdmConfigTemplateEntity.PdmConfigTemplateName }}({{
            PdmConfigTemplateEntity.PdmConfigTemplateID
          }})
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <ConfigKey_select
          :dense="true"
          outlined
          class="col-3"
          label="选择主模块"
          v-model="PdmConfigTemplateEntity.CONFIG_KEY_NO"
          v-model:valueCode="PdmConfigTemplateEntity.CONFIG_KEY_NO"
        />
        <q-input
          label="子模块名称"
          dense
          outlined
          class="col-3"
          v-model="PdmConfigTemplateEntity.CONFIG_TEM_NAME"
        />
        <q-input
          label="子模块图片名称"
          dense
          outlined
          class="col-3"
          v-model="PdmConfigTemplateEntity.CONFIG_TEM_TU"
        />
         <q-input
          label="排列序号"
          dense
          outlined
          class="col-2"
          v-model="PdmConfigTemplateEntity.CONFIG_TEM_NO"
        />
        <q-input
          label="备注"
          dense
          outlined
          class="col-11"
          v-model="PdmConfigTemplateEntity.Config_Tem_Memo"
        />
          <q-input
          label="小程序跳转路径"
          dense
          outlined
          class="col-11"
          v-model="PdmConfigTemplateEntity.CONFIG_TEM_PATH"
        />
        <q-btn-toggle
          v-model="PdmConfigTemplateEntity.CONFIG_TEM_TYPE"
          push
          glossy
          toggle-color="primary"
          :options="[
            { label: '门诊', value: 1 },
            { label: '住院', value: 2},
            { label: '体检', value: 3},
            { label: '其他', value: 4},
            { label: '我的', value: 99},
          ]"
        />
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ConfigKey_select from "@/views/pdm/comm/configKey_select.vue";
export default {
  components: {
    ConfigKey_select,
  },
  props: {},
  computed: {
    ...mapState("PdmConfigTemplate", [
      "PdmConfigTemplateShowDlg",
      "PdmConfigTemplateEntity",
      "PdmConfigTemplateList",
    ]),
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("PdmConfigTemplate", [
      "actUpdatePdmConfigTemplate",
      "actGetPdmConfigTemplateList",
    ]),
    onOK() {
      this.actUpdatePdmConfigTemplate({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: this.PdmConfigTemplateEntity,
      }).then((res) => {
        if (res.Code == 0) {
          // setTimeout(()=> {
          //   this.$emit("onLoadData");
          // }, 500);
          this.$emit("onLoadData");
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
  },
};
</script>
